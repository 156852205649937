<template>
  <b-container fluid>
    <b-row>
      <b-col cols="10">
        <h1>
          <b-button
            variant="flat-primary"
            :to="`/pages/edital-verticalizado/edital/${idEdital}`"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              size="25"
              v-b-tooltip.hover
              title="Voltar"
            />
          </b-button>
          Modo edição do Edital
        </h1>
      </b-col>
      <b-col cols="2">
        <b-button
          @click="[(modalConfigurarRevisoes = true)]"
          class="btn-icon"
          variant="primary"
          block
        >
          <feather-icon icon="SettingsIcon" />
          Configurar revisões
        </b-button>
      </b-col>
      <!--b-col cols="2">
                <b-button @click="[modalCriarDisciplina = true]" class="btn-icon" variant="success" block>
                    <feather-icon icon="PlusIcon" />
                    Nova Disciplina
                </b-button>
            </b-col-->
    </b-row>
    <br />
    <div v-if="disciplinasEdital.length > 0">
      <div v-for="(disciplinas, idx) in disciplinasEdital" :key="idx">
        <b-table
          striped
          hover
          :fields="fields"
          :items="disciplinas.topicos"
          :tbody-tr-class="rowClass"
        >
          <template #table-colgroup="scope">
            <col
              style="text-align: center"
              v-for="field in scope.fields"
              :key="field.key"
            />
          </template>
          <template #head(topicos)>
            {{ disciplinas.nome }}

            <!-- <a href="#" variant="primary" style="margin-left: 10px">
                                    <feather-icon v-b-tooltip.hover title="Novo Tópico" icon="PlusIcon" />
                                </a>

                                <a href="#" variant="danger" style="margin-left: 10px">
                                    <feather-icon v-b-tooltip.hover title="Remover Disciplina" icon="TrashIcon" />
                                </a>
                                
                                <br><br> -->
            <a
              @click.prevent="[(modalCriarTopico = true)]"
              v-b-tooltip.hover
              title="Novo Tópico"
              class="ml-1 btn-icon rounded-circle"
              variant="success"
            >
              <feather-icon icon="PlusIcon" />
            </a>
            <a
              @click.prevent="
                [
                  (openModalRemoverDisciplina = true),
                  (topicoRemove = disciplinas),
                  confirmRemoverDisciplina(),
                ]
              "
              v-b-tooltip.hover
              title="Remover Disciplina"
              class="ml-1 btn-icon rounded-circle"
              variant="danger"
            >
              <feather-icon icon="TrashIcon" />
            </a>
          </template>
          <template #cell(aproveitamento)="data">
            <div style="text-align: center">
              <!-- {{ data.item.aproveitamento ? `${data.item.aproveitamento}%` : '0%' }} -->
              <b-progress
                height="1.5rem"
                :variant="`${barColor(data.item.aproveitamento)}`"
                :value="data.item.aproveitamento"
                max="100"
                show-progress
                animated
              ></b-progress>
            </div>
          </template>
          <!--template #cell(incidencia)=" data " style="width: 1rem">
                        <{{ data.item.aproveitamento }}>
                        <div :class="`${apriveitamentoColor(data.item.aproveitamento)} incidencia-card`">
                            {{ data.item.incidencia ? `${data.item.incidencia}%` : '0%' }}
                        </div>
                    </template-->

          <template #cell(topicos)="row">
            <b-row>
              <b-col>
                <div class="topico-nome" @click="row.toggleDetails">
                  {{ row.item.nome }}
                </div>
              </b-col>
              <b-col>
                <a href="#" variant="primary">
                  <feather-icon @click="row.toggleDetails" icon="PlusIcon" />
                </a>
              </b-col>
            </b-row>
          </template>
          <template #row-details="row">
            <b-card>
              <b-card-text>
                <b-row>
                  <b-col class="mb-2" cols="12">
                    <strong>
                      {{ row.item.nome }}
                    </strong>
                  </b-col>
                  <b-col cols="12">
                    <div>Aproveitamento</div>
                    <br />
                    <b-progress
                      height="1.5rem"
                      :variant="`${barColor(row.item.aproveitamento)}`"
                      :value="row.item.aproveitamento"
                      max="100"
                      show-progress
                      animated
                    ></b-progress>
                  </b-col>
                  <b-col cols="6">
                    <b-form-input
                      v-model="row.item.acertos"
                      class="mt-2"
                      placeholder="Acertos Ex.: 10"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="6">
                    <b-form-input
                      v-model="row.item.erros"
                      class="mt-2"
                      placeholder="Erros Ex.: 5"
                    ></b-form-input>
                  </b-col>
                  <b-col cols="12">
                    <b-button
                      @click="cadastrarAcertosErros(row.item)"
                      class="mt-2"
                      variant="primary"
                      >Registrar</b-button
                    >
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
          </template>

          <template class="align-check" #cell(estudado)="data">
            <b-form-checkbox
              :checked="data.item.data_finalizacao ? true : false"
              @change="
                [
                  checkEstudado(data.item, disciplinas.revisoes),
                  resetDisciplinaTopicosChecked(disciplinas.id),
                ]
              "
              class="align-check"
            ></b-form-checkbox>
          </template>
          <!-- 
                        O trecho de código a seguir foi feito dessa forma para adequar ao JSON retornado
                    -->
          <template class="align-check" #cell(revisao24h)="data">
            <b-row>
              <b-col cols="3">
                <b-form-checkbox
                  v-if="checkIntervaloUmDia(data.item.rev_1)"
                  class="align-check"
                  :disabled="true"
                ></b-form-checkbox>
                <b-form-checkbox
                  v-b-tooltip.hover
                  :title="data.item.rev_1[0].data"
                  v-else
                  @change="checkRevisoes(data.item, data.item.rev_1[0])"
                  class="align-check"
                  :checked="checkSelectRevisoes(data.item.rev_1[0])"
                ></b-form-checkbox>
              </b-col>
              <b-col cols="3">
                <a
                  @click="removeRevisaoTopico(data.item, data.item.rev_1[0].id)"
                  v-if="!checkIntervaloUmDia(data.item.rev_1)"
                  href="#"
                  variant="danger"
                >
                  <feather-icon
                    v-b-tooltip.hover
                    title="Remover revisão"
                    class="mb-2"
                    icon="TrashIcon"
                  />
                </a>
              </b-col>
            </b-row>
          </template>
          <template class="align-check" #cell(revisao7dias)="data">
            <b-row>
              <b-col cols="3">
                <b-form-checkbox
                  v-if="checkIntervaloSeteDias(data.item.rev_7)"
                  class="align-check"
                  :disabled="true"
                ></b-form-checkbox>
                <b-form-checkbox
                  v-b-tooltip.hover
                  :title="data.item.rev_7[0].data"
                  v-else
                  @change="checkRevisoes(data.item, data.item.rev_7[0])"
                  class="align-check"
                  :checked="checkSelectRevisoes(data.item.rev_7[0])"
                ></b-form-checkbox>
              </b-col>
              <b-col cols="3">
                <a
                  @click="removeRevisaoTopico(data.item, data.item.rev_7[0].id)"
                  v-if="!checkIntervaloSeteDias(data.item.rev_7)"
                  href="#"
                  variant="danger"
                >
                  <feather-icon
                    v-b-tooltip.hover
                    title="Remover revisão"
                    class="mb-2 danger"
                    icon="TrashIcon"
                  />
                </a>
              </b-col>
            </b-row>
          </template>
          <template class="align-check" #cell(revisao30dias)="data">
            <b-row>
              <b-col cols="3">
                <b-form-checkbox
                  v-if="checkIntervaloTrintaDias(data.item.rev_30)"
                  class="align-check"
                  :disabled="true"
                ></b-form-checkbox>
                <b-form-checkbox
                  v-b-tooltip.hover
                  :title="data.item.rev_30[0].data"
                  v-else
                  @change="checkRevisoes(data.item, data.item.rev_30[0])"
                  class="align-check"
                  :checked="checkSelectRevisoes(data.item.rev_30[0])"
                ></b-form-checkbox>
              </b-col>
              <b-col cols="3">
                <a
                  @click="
                    removeRevisaoTopico(data.item, data.item.rev_30[0].id)
                  "
                  v-if="!checkIntervaloTrintaDias(data.item.rev_30)"
                  href="#"
                  variant="danger"
                >
                  <feather-icon
                    v-b-tooltip.hover
                    title="Remover revisão"
                    class="mb-2 danger"
                    icon="TrashIcon"
                  />
                </a>
              </b-col>
            </b-row>
          </template>
          <template class="align-check" #cell(acoes)="data">
            <b-button
              class="btn-icon"
              variant="flat-danger"
              @click="removeTopico(data.item)"
              v-b-tooltip.hover
              title="Remover tópico"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal
      v-model="modalInfoDisciplina"
      ok-title="Ok"
      ok-only
      title="Disciplina"
    >
      <b-row v-if="disciplinaInfoData">
        <b-col cols="12">
          <strong>Aproveitamento: </strong><br />
          {{ `${disciplinaInfoData.aproveitamento}%` }}
        </b-col>
        <b-col cols="12">
          <strong>Progresso: </strong><br />
          {{ `${disciplinaInfoData.progresso}%` }}
        </b-col>
        <b-col cols="12">
          <strong>Porcentagem da última prova: </strong><br />
          {{ `${disciplinaInfoData.porcentagem_ultima_prova}%` }}
        </b-col>
        <b-col cols="12">
          <strong>Finalizações: </strong><br />
          {{ `${disciplinaInfoData.finalizacoes}` }}
        </b-col>
      </b-row>
    </b-modal>
    <!-- MODAL NOVA DISCIPLINA -->
    <b-modal
      ok-title="Ok"
      cancel-title="Cacelar"
      title="Nova Disciplina"
      v-model="modalCriarDisciplina"
      @ok="novaDisciplinaToEdital()"
    >
      <b-card-text>
        <b-form-select
          v-model="novaDisiplinaID"
          :options="disciplinas"
          value-field="id"
          text-field="nome"
        ></b-form-select>
      </b-card-text>
    </b-modal>

    <!-- MODAL NOVO TÓPICO -->
    <b-modal
      ok-title="Ok"
      cancel-title="Cacelar"
      title="Novo Tópico"
      v-model="modalCriarTopico"
      @ok="novoTopicoToDisciplina()"
    >
      <b-card-text>
        <b-form-input v-model="novoTopicoNome"></b-form-input>
      </b-card-text>
    </b-modal>

    <b-modal
      ok-title="Ok"
      cancel-title="Cacelar"
      title="Configurar Revisões"
      v-model="modalConfigurarRevisoes"
      @ok="[(modalConfigurarRevisoes = false)]"
    >
      <b-card-text>
        <b-form-checkbox-group v-model="configuracaoRevisaoSelected">
          <b-form-checkbox @change="configurarRevisoes(1, 0)" value="1">
            Revisão de 1 dia
          </b-form-checkbox>
          <br />
          <b-form-checkbox @change="configurarRevisoes(2, 0)" value="7">
            Revisão de 7 dias
          </b-form-checkbox>
          <br />
          <b-form-checkbox @change="configurarRevisoes(3, 1)" value="30">
            Revisão de 30 dias
          </b-form-checkbox>
          |
          <span style="padding-right: 20px"></span>
          <b-form-checkbox> Recorrente </b-form-checkbox>
        </b-form-checkbox-group>
      </b-card-text>
    </b-modal>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BContainer,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormInput,
  BButton,
  BCard,
  BCardText,
  BProgress,
  BIcon,
  BTooltip,
  VBTooltip,
  BFormSelect,
} from "bootstrap-vue";
export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BTable,
    BContainer,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormInput,
    BButton,
    BCard,
    BCardText,
    BProgress,
    BIcon,
    BTooltip,
    BFormSelect,
  },
  data: () => ({
    modalInfoDisciplina: false,
    openModalRemoverDisciplina: false,
    topicoRemove: null,
    colsTopicoNome: 6,
    colsEstudado: 1,
    colsTableRevisoes: 6,
    disciplinasEdital: [],
    disciplinas: [],
    idEdital: null,
    idDisciplina: null,
    topicosSelected: [],
    fields: [
      { key: "topicos", label: "Tópicos" },
      //{ key: 'incidencia', label: 'Incidência'},
      { key: "aproveitamento", label: "Aproveitamento" },
      { key: "estudado", label: "Estudado" },
      { key: "revisao24h", label: "Revisão de 24h" },
      { key: "revisao7dias", label: "Revisão de 7 dias" },
      { key: "revisao30dias", label: "Revisão de 30 dias" },
      { key: "acoes", label: "Ações" },
    ],
    acertos: "",
    erros: "",
    disciplinaInfoData: null,
    modalCriarDisciplina: null,
    modalCriarTopico: null,
    modalConfigurarRevisoes: null,
    novaDisiplinaID: null,
    disciplinasTopicos: null,
    novoTopicoNome: null,
    recorrente: 0,
    topicosDisciplinasEdital: [],
    configuracaoRevisao: [],
    configuracaoRevisaoSelected: [],
  }),
  methods: {
    confirmRemoverDisciplina() {
      this.$bvModal
        .msgBoxConfirm("Deseja remover essa Disciplina?", {
          cancelTitle: "Cancelar",
          okTitle: "Remover",
        })
        .then((value) => {
          if (value) {
            this.removeDisciplina();
          }
        });
    },
    removeTopico(topico) {
      const { id, disciplina_id } = topico;

      this.$http
        .delete(
          `/editais/${this.idEdital}/disciplinas/${disciplina_id}/topicos/${id}`
        )
        .then((response) => {
          this.getDisciplinasEdital();
        });
    },
    removeDisciplina() {
      const { id } = this.topicoRemove;
      this.$http
        .delete(`/editais/${this.idEdital}/disciplinas/${id}`)
        .then((response) => {
          this.$router.push(
            `/pages/edital-verticalizado/edital/${this.idEdital}`
          );
        });
    },
    disciplinaInfo(topicos) {
      const { id } = topicos;
      this.$http
        .get(`/editais/${this.idEdital}/disciplinas/${id}/info`)
        .then((response) => {
          this.disciplinaInfoData = response.data;
        });
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (!item.topico_id) {
        return "topico-sem-id";
      }
    },
    getAllDisciplinas() {
      this.$http.get("disciplinas").then((response) => {
        this.disciplinas = response.data;
      });
    },
    getRevisoesDisciplinas() {
      this.$http
        .get(
          `/editais/${this.idEdital}/disciplinas/${this.idDisciplina}/revisoes`
        )
        .then((response) => {
          this.configuracaoRevisao = response.data;

          this.configuracaoRevisao.map((rev) => {
            this.configuracaoRevisaoSelected.push(rev.intervalo);
          });
        });
    },
    novaDisciplinaToEdital() {
      this.$http
        .post(`/editais/${this.idEdital}/disciplinas/storePrivate`, {
          disciplina_id: this.novaDisiplinaID,
        })
        .then((response) => {
          let disciplinaId = response.data;
          this.$router.push(
            `/pages/edital-verticalizado/edital/${this.idEdital}/modo-edicao/disciplina/${disciplinaId}`
          );
          location.reload();
        });
    },
    novoTopicoToDisciplina() {
      this.$http
        .post(
          `/editais/${this.idEdital}/disciplinas/${this.idDisciplina}/topicos/storePrivate`,
          {
            nome: this.novoTopicoNome,
          }
        )
        .then((response) => {
          this.getDisciplinasEdital();
        });
    },
    getDisciplinasEdital() {
      this.$http
        .get(`/editais/${this.idEdital}/disciplinas/${this.idDisciplina}`)
        .then((response) => {
          this.disciplinasEdital = [];
          this.disciplinasEdital.push(response.data);
        });
    },
    getAproveitamentoEditar(idAproveitamento) {
      this.$http
        .get(
          `/editais/${this.idEdital}/disciplinas/${this.idDisciplina}/topicos/${idAproveitamento}/aproveitamento`
        )
        .then((response) => {
          this.topicosDisciplinasEdital = response.data;
          this.acertos = this.topicosDisciplinasEdital.acertos;
          this.erros = this.topicosDisciplinasEdital.erros;
        });
    },
    removeRevisaoTopico(topico, idRevisao) {
      const { id } = topico;
      this.$http
        .delete(
          `/editais/${this.idEdital}/disciplinas/${this.idDisciplina}/topicos/${id}/revisoes/${idRevisao}`
        )
        .then((response) => {
          this.getDisciplinasEdital();
        });
    },
    async configurarRevisoes(intervalo, recorrencia) {
      // first get revisaoId
      const revisaoId = await this.configuracaoRevisao.filter((rev) => {
        if (rev.intervalo == 1 && intervalo == 1) {
          return rev.id;
        } else if (rev.intervalo == 2 && intervalo == 7) {
          return rev.id;
        } else if (rev.intervalo == 3 && intervalo == 30) {
          return rev.id;
        }
      });
      console.log(revisaoId);

      if (revisaoId && revisaoId.length > 0) {
        this.$http
          .delete(
            `/editais/${this.idEdital}/disciplinas/${this.idDisciplina}/revisoes/${revisaoId[0].id}`
          )
          .then((response) => {
            this.getDisciplinasEdital();
            this.getRevisoesDisciplinas();
          });
      } else {
        this.$http
          .post(
            `/editais/${this.idEdital}/disciplinas/${this.idDisciplina}/revisoes/store`,
            {
              id: intervalo,
              recorrente: recorrencia == 1 ? this.recorrencia : 0,
            }
          )
          .then((response) => {
            this.getDisciplinasEdital();
            this.getRevisoesDisciplinas();
          });
      }
    },
    checkEstudado(topico, revisoes) {
      const { id, disciplina_id, data_finalizacao, topico_id } = topico;

      let check = data_finalizacao ? 0 : 1;

      this.$http
        .put(
          `/editais/${this.idEdital}/disciplinas/${disciplina_id}/topicos/${id}/edit`,
          {
            checkbox: check,
            revisoes: revisoes,
            topico_id: topico_id,
          }
        )
        .then((response) => {
          this.getDisciplinasEdital();
        });
    },
    apriveitamentoColor(aproveitamento) {
      if (!aproveitamento) {
        return "bg-purple-1";
      }

      if (aproveitamento > 0 && aproveitamento < 30) {
        return "bg-purple-1";
      }

      if (aproveitamento > 31 && aproveitamento < 60) {
        return "bg-purple-2";
      }

      if (aproveitamento > 61 && aproveitamento < 80) {
        return "bg-purple-3";
      }

      if (aproveitamento > 81) {
        return "bg-purple-4";
      }
    },
    barColor(aproveitamento) {
      if (!aproveitamento) {
        return "danger";
      }

      if (aproveitamento < 30) {
        return "danger";
      }

      if (aproveitamento >= 31) {
        return "success";
      }
    },
    checkIntervaloUmDia(revisaoData) {
      if (typeof revisaoData[0] === "undefined") {
        return true;
      } else {
        return false;
      }
    },
    checkIntervaloSeteDias(revisaoData) {
      if (typeof revisaoData[0] === "undefined") {
        return true;
      } else {
        return false;
      }
    },
    checkEstudadoStatus(data_finalizacao) {
      if (data_finalizacao) {
        return true;
      }
    },
    checkIntervaloTrintaDias(revisaoData) {
      if (typeof revisaoData[0] === "undefined") {
        return true;
      } else {
        return false;
      }
    },
    checkSelectRevisoes(revisaoData) {
      return revisaoData.data_finalizacao ? true : false;
    },
    checkRevisoes(topico, revisaoData) {
      const { id, disciplina_id } = topico;
      const { id: revisao_id, data_finalizacao } = revisaoData;

      let check = data_finalizacao ? 0 : 1;

      let route = `/editais/${this.idEdital}/disciplinas/${disciplina_id}/topicos/${id}/revisoes/${revisao_id}/edit`;
      this.$http
        .put(route, {
          checkbox: check,
        })
        .then((response) => {
          this.getDisciplinasEdital();
        });
    },
    cadastrarAcertosErros(topico) {
      const { id, disciplina_id, acertos, erros } = topico;

      let route = `/editais/${this.idEdital}/disciplinas/${disciplina_id}/topicos/${id}/edit/aproveitamento`;
      this.$http
        .put(route, {
          acertos: acertos,
          erros: erros,
        })
        .then((response) => {
          this.getDisciplinasEdital();
          this.acertos = null;
          this.erros = null;
        });
    },
  },
  created() {
    this.idEdital = this.$route.params.id;
    this.idDisciplina = this.$route.params.idDisciplina;
    this.getRevisoesDisciplinas();
    this.getDisciplinasEdital();
    this.getAllDisciplinas();
  },
};
</script>

<style>
.bg-purple-1,
.bg-purple-2,
.bg-purple-3,
.bg-purple-4 {
  color: #ffffff !important;
}
.bg-purple-1 {
  background-color: #8e24aa;
}

.bg-purple-2 {
  background-color: #7b1fa2;
}

.bg-purple-3 {
  background-color: #6a1b9a;
}

.bg-purple-4 {
  background-color: #4a148c;
}

.align-check {
  max-width: 18px;
  margin: auto auto;
}
/* 
tr:nth-child(even) {
  background-color: #dddddd;
} */

.incidencia-card {
  width: 20px;
  height: 20px;
  border-radius: 0.2em;
  margin: auto auto;
  color: #000000;
  text-align: center;
  vertical-align: center;
  padding: 8px 0 0 0;
}

.topico-nome {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.topico-sem-id {
  border-top: solid rgba(115, 103, 240, 0.12) 2px !important;
  border-bottom: solid rgba(115, 103, 240, 0.12) 2px !important;
}
</style>
